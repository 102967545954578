<template>
    <div class=" pa-3">
        <v-dialog v-model="dialogAwait" persistent width="13vw">
            <div class="d-flex justify-center align-center" style="height: 60px;background-color: azure;">
                <span>Processando dados...</span>
                <v-progress-circular :width="3" color="green" indeterminate></v-progress-circular>
            </div>
        </v-dialog>
        <v-dialog v-model="dialogEdit" persistent width="30vw">
            <v-card>
                <v-card-title>Editar valor do item</v-card-title>
                <v-card-subtitle>{{
            `${editedItem.ANOMES}-${editedItem.BRANCH}-${editedItem.ACCOUNTING}-${editedItem.NATURE}`
        }}</v-card-subtitle>
                <v-card-text>
                    <div class="d-flex justify-center align-center">
                        <v-col cols="6">
                            <v-text-field v-model.lazy="cost" label="Valor" class="text-money my-1 mx-05 font-08" dense
                                type="text" filled></v-text-field>
                        </v-col>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn x-small class="error" @click="dialogEdit = !dialogEdit">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn x-small class="primary" @click="saveEdit">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogInsert" persistent width="70vw">
            <v-card>
                <v-card-title>Inclusão</v-card-title>

                <v-card-subtitle>Lançamentos</v-card-subtitle>

                <v-card-text>
                    <div class="d-flex justify-center align-center">
                        <v-switch v-model="appellant" label="Lançar recorrência" color="orange" value="orange"
                            hide-details></v-switch>
                    </div>
                    
                        <div  class="d-flex align-center">
                            <v-col cols="3">
                                <v-autocomplete v-model='startYear' :items="years" label="Ano de" auto-select-first
                                    dense filled>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete v-model='startMonth' :items="months" item-text="text" item-value="value"
                                    label="Mês de" auto-select-first dense filled>
                                </v-autocomplete>
                            </v-col>
                            <v-expand-transition>
                                <div v-if='appellant' class="d-flex align-center justify-space-around" style="width: 35vw;">
                                    <v-col cols="6">
                                        <v-autocomplete v-model='endYear' :items="years" label="Ano até" auto-select-first dense
                                            filled>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete v-model='endMonth' :items="months" item-text="text" item-value="value"
                                            label="Mês até" auto-select-first dense filled>
                                        </v-autocomplete>
                                    </v-col>
                                </div>
                            </v-expand-transition>
                        </div>
            
                    <div class="d-flex align-center">
                        <v-col cols="4">
                            <v-autocomplete v-model='unit' :items="branches" item-text="text" item-value="value"
                                label="Unidade" auto-select-first dense filled hide-details>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete v-model='accounting' :items="itemsAccounting" item-text="text"
                                item-value="value" label="Centro de custo" auto-select-first dense filled hide-details>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete v-model='nature' :items="itemsNature" item-text="text" item-value="value"
                                label="Natureza" auto-select-first dense filled hide-details>
                            </v-autocomplete>
                        </v-col>
                    </div>
                    <div class="d-flex justify-center align-center">
                        <v-col cols="3">
                            <v-text-field v-model.lazy="cost" label="Valor" class="text-money my-1 mx-05 font-08" dense
                                type="text" filled></v-text-field>
                        </v-col>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn x-small class="error" @click="dialogInsert = !dialogInsert">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn x-small class="primary" @click="insertData">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="d-flex justify-center align-center">
            <h1>PCO - Planejamento e controle orçamentário.</h1>
        </div>

        <div class="d-flex justify-start align-center">
            <v-col cols="2">
                <v-autocomplete v-model='yearFrom' :items="years" label="Ano de?" auto-select-first dense filled>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete v-model='monthFrom' :items="months" item-text="text" item-value="value" label="Mês de?"
                    auto-select-first dense filled>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete v-model='yearUntil' :items="years" label="Ano até?" auto-select-first dense filled>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete v-model='monthUntil' :items="months" item-text="text" item-value="value"
                    label="Mês até?" auto-select-first dense filled>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-autocomplete v-model='filterBranch' :items="branches" item-text="text" item-value="value"
                    label="Filtrar por unidade?" auto-select-first dense filled>slot
                </v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn class="primary mr-5" x-small @click="searchData">Carregar</v-btn>

        </div>

        <div class="bodyContent">
            <v-data-table dense :headers="dataHeader" :items="dataTableCpo" class="elevation-1"
                :items-per-page="dataTableCpo.length" :search="searchDataTable" fixed-header
                no-data-text="Sem lançamentos no momento" :loading="loadingTable"
                loading-text="Buscando dados aguarde..." hide-default-footer style="width: 96vw" height="33vw">
                <template v-slot:top>
                    <div class="d-flex justify-space-between align-center pa-3">
                        <v-btn  fab x-small dark color="indigo"
                            @click="dialogInsert = !dialogInsert">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                        <v-switch v-model="switchAccounting" label="Crédito" color="green" value="1"
                        hide-details @click="validSwitchApplied(switchAccounting)"></v-switch>
                        <v-switch v-model="switchNature" label="Débito" color="red" value="2" hide-details
                            @click="validSwitchApplied(switchNature)"></v-switch>
                        <v-col cols="4">
                            <v-text-field :disabled="distancePeriod < 0" v-model="searchDataTable"
                                append-icon="mdi-magnify" label="Buscar" single-line dense hide-details></v-text-field>
                        </v-col>
                        <v-tooltip bottom>
                            <v-spacer></v-spacer>
                            <template v-slot:activator="{ on, attrs }">
                            <vue-excel-xlsx
                                :data="itemsRelatExcel"
                                :columns="headersRelatExcel"
                                :fileName="`PCO de ${yearFrom}${monthFrom} até ${yearUntil}${monthUntil}`"
                                sheetName="Dados"
                                class="ml-5 mb-5 tbExcel"
                            >
                                <v-btn
                                icon
                                color="blue darken-2"
                                class="mr-5 mt-5"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </vue-excel-xlsx>
                            </template>
                            <span>Exportar para excel</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">

                    <div class="d-flex justify-space-around align-center">
                        <v-icon color="green" @click="editItem(item)" size="20">mdi-pencil</v-icon>
                        <v-icon color="red" @click="deleteItem(item)" size="20">mdi-delete-empty</v-icon>
                    </div>
                </template>
                <template v-slot:[`item.TIPO`]="{item}">
                    <div class="pa-2">
                    <v-chip x-small v-if="item.TIPO === 'Débito'" color="red" text-color="white">{{ item.TIPO }}</v-chip>
                    <v-chip x-small v-if="item.TIPO === 'Crédito'" color="green" text-color="white">{{ item.TIPO }}</v-chip>
                </div>
                </template>
            </v-data-table>
        </div>
        <div class="d-flex justify-space-around align-center mt-5">
            <h3>Total Créditos: {{ totCredito.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</h3>
            <h3>Total Débitos: {{ totDebito.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })
                }}
            </h3>
            <h2>Total: {{ totCost.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</h2>
        </div>
    </div>
</template>
<script>
import { stringToDate } from "@/utils/dateParse";
import axios from "axios";
import { add, format, formatDistanceStrict, sub } from "date-fns";

export default {
    name: "cpo-opimed",
    computed: {
        totCredito() {
            return this.dataTableCpo.filter(e => Number(e.ZZE_NATURE.charAt(0)) <= 1).reduce((total, item) => total + item.ZZE_VALOR, 0)
        },
        totDebito() {
            return this.dataTableCpo.filter(e => Number(e.ZZE_NATURE.charAt(0)) > 1).reduce((total, item) => total + item.ZZE_VALOR, 0)
        },
        totCost() {
            return this.totCredito - this.totDebito
        },
    },
    data: () => {
        return {
            itemsRelatExcel: [],
            headersRelatExcel: [
            { label: "Ano/Mês",         field: "ANOMES" },
            { label: "Unidade",         field: "BRANCH" },
            { label: "Centro de Custo", field: "ACCOUNTING"},
            { label: "Natureza",        field: "NATURE" },
            { label: "Tipo",            field: "TIPO" },
            { label: "Valor",           field: "COST" },
            ],
            nomeRel: '',
            dialogAwait: false,
            dialogEdit: false,
            switchAccounting: '',
            switchNature: '',
            years: [],
            appellant: false,
            loadingYears: true,
            yearFrom: null,
            monthFrom: null,
            yearUntil: null,
            monthUntil: null,
            filterBranch: null,
            months: [
                { text: 'Janeiro', value: '01' },
                { text: 'Fevereiro', value: '02' },
                { text: 'Março', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Maio', value: '05' },
                { text: 'Junho', value: '06' },
                { text: 'Julho', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Setembro', value: '09' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' },
            ],
            branches: [],
            unit: [],
            loadingTable: false,
            dataHeader: [
                { text: "Ano/Mês", value: "ANOMES", align: "start", width: 50, caption: "caption" },
                { text: "Unidade", value: "BRANCH", align: "start", width: 150, caption: "caption" },
                { text: "Centro de Custo", value: "ACCOUNTING", align: "start", width: 200, caption: "caption" },
                { text: "Natureza", value: "NATURE", align: "start", width: 200, caption: "caption" },
                { text: "Tipo", value: "TIPO", align: "start", width: 50, caption: "caption" },
                { text: "Valor", value: "COST", align: "end", width: 50, caption: "title" },
                { text: "Ações", value: "actions", align: "start", width: 50, caption: "caption" }
            ],
            dataTableCpo: [],
            dataTableCpoOld: [],
            distancePeriod: -1,
            searchDataTable: '',
            accounting: null,
            itemsAccounting: [],
            nature: null,
            itemsNature: [],
            dialogInsert: false,
            startYear: null,
            startMonth: null,
            endYear: null,
            endMonth: null,
            cost: null,
            editedItem: {},
        }
    },
    methods: {
        editItem(item) {
            this.editedItem = item
            this.cost = item.COST
            this.dialogEdit = true
        },

        validSwitchApplied(item) {
            if (item) {
                if(Number(item) <= 1){                    
                    this.dataTableCpo = this.dataTableCpoOld.filter(e => Number(e.ZZE_NATURE.charAt(0)) <=1)
                }else{
                    this.dataTableCpo = this.dataTableCpoOld.filter(e => Number(e.ZZE_NATURE.charAt(0)) >1)
                }
            } else {
                this.dataTableCpo = this.dataTableCpoOld
            }
        },
        async searchYears() {
            const yearsConstruct = sub(new Date(), { years: 2 });

            for (let cont = 0; cont <= 3; cont++) {
                if (
                    add(yearsConstruct, { years: cont }).getFullYear() <=
                    new add(new Date(), { years: 1 })
                ) {
                    this.years.push(add(yearsConstruct, { years: cont }).getFullYear());
                }
            }

            this.years = this.years.reverse();
            this.loadingYears = false;
        },
        async searchBranches() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/getBranches`;

            const dataBranches = await axios.get(url);

            if (!dataBranches && dataBranches.status !== 200) {
                this.$toast.error('Erro ao buscar Filiais!', {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }

            this.branches = dataBranches.data.map(e => {
                return {
                    text: `${e.CTT_CUSTO?.trim()}-${e.CTT_DESC01?.trim()}`,
                    value: e.CTT_CUSTO?.trim()
                }
            })

            this.branches.push({
                text: 'Sem filial filtrada',
                value: null
            })
        },
        async searchData() {
            this.distancePeriod = -1
            if (!this.yearFrom || !this.monthFrom || !this.yearUntil || !this.monthUntil) {
                this.$toast.error('Por favor informar todos os campos de ano e mês!', {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                return
            }

            this.loadingTable = true
            this.dialogAwait = true
            const distance = formatDistanceStrict(
                stringToDate(`${this.yearFrom}${this.monthFrom}01`),
                stringToDate(`${this.yearUntil}${this.monthUntil}01`), {
                unit: 'month',
                roundingMethod: 'ceil'
            })

            this.distancePeriod = Number(distance.split(' ')[0])

            const bodyContent = {
                periodOne: `${this.yearFrom}${this.monthFrom}`,
                periodTwo: `${this.yearUntil}${this.monthUntil}`,
                branch: this.filterBranch
            }

            const url = `${process.env.VUE_APP_BASE_API_URL}/getDataCpo`;

            const dataCpo = await axios.post(url, bodyContent);

            if (!dataCpo && dataCpo.status !== 200) {
                this.$toast.error('Erro ao buscar dados do custo operacional!', {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }

            this.loadingTable = false

            this.dataTableCpo = dataCpo.data.map(e => {
                return {
                    ...e,
                    ANOMES: `${e.ZZE_ANOMES.substr(0, 4)}/${e.ZZE_ANOMES.substr(4, 2)}`,
                    BRANCH: `${e.ZZE_UNIDAD}-${e.CTT_DESC01}`,
                    ACCOUNTING: `${e.ZZE_CTOC}-${e.CTD_DESC01}`,
                    NATURE: `${e.ZZE_NATURE}-${e.ED_DESCRIC}`,
                    COST: e.ZZE_VALOR.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    TIPO: Number(e.ZZE_NATURE.charAt(0)) <= 1 ? 'Crédito' : 'Débito'

                }
            })

            this.dataTableCpoOld = dataCpo.data.map(e => {
                return {
                    ...e,
                    ANOMES: `${e.ZZE_ANOMES.substr(0, 4)}/${e.ZZE_ANOMES.substr(4, 2)}`,
                    BRANCH: `${e.ZZE_UNIDAD}-${e.CTT_DESC01}`,
                    ACCOUNTING: `${e.ZZE_CTOC}-${e.CTD_DESC01}`,
                    NATURE: `${e.ZZE_NATURE}-${e.ED_DESCRIC}`,
                    COST: e.ZZE_VALOR.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    TIPO: Number(e.ZZE_NATURE.charAt(0)) <= 1 ? 'Crédito' : 'Débito'
                }
            })

            this.itemsRelatExcel = this.dataTableCpoOld

            this.dialogAwait = false
        },
        async searchAccounting() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/getAccounting`;

            const accounting = await axios.get(url);

            if (!accounting && accounting.status !== 200) {
                this.$toast.error('Erro ao buscar Filiais!', {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }

            this.itemsAccounting = accounting.data.map(e => {
                return {
                    text: `${e.CTD_ITEM}-${e.CTD_DESC01}`,
                    value: e.CTD_ITEM
                }
            })

        },
        async searchNature() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/getNature`;

            const nature = await axios.get(url);

            if (!nature && nature.status !== 200) {
                this.$toast.error('Erro ao buscar Filiais!', {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
            }

            const naturez = nature.data.filter(e=> !isNaN(e.ED_CODIGO))

            this.itemsNature = naturez.map(e => {
                return {
                    text: `${e.ED_CODIGO}-${e.ED_DESCRIC}`,
                    value: e.ED_CODIGO
                }
            })
        },
        async insertData() {
            let proceed = true

            if (this.appellant) {
                if (!this.endYear || !this.endMonth) proceed = false
            }

            if(!this.startYear || !this.startMonth) proceed = false

            if (!this.unit) proceed = false
            if (!this.accounting && !this.nature) proceed = false

            if (!this.cost) proceed = false


            if (!proceed) {
                this.$toast.error('Dados insuficientes para inclusão!', {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                return
            }

            this.dialogAwait = true

            let periodContent = []

            const value = this.cost.split(',')[0]
            const cents = this.cost.split(',')[1] ?? '00'

            if (this.appellant) {
                const distance = formatDistanceStrict(
                    stringToDate(`${this.startYear}${this.startMonth}01`),
                    stringToDate(`${this.endYear}${this.endMonth}01`), {
                    unit: 'month',
                    roundingMethod: 'ceil'
                })
                this.distancePeriod = Number(distance.split(' ')[0])

                for (let cont = 0; cont < this.distancePeriod; cont++) {

                    const result = add(stringToDate(`${this.startYear}${this.startMonth}01`), {
                        months: cont
                    })

                    periodContent.push({
                        period: format(result, 'yyyyMM')
                    })
                }

                const url = `${process.env.VUE_APP_BASE_API_URL}/getDataCpo`;

                const bodyContent = {
                    periodOne: `${this.startYear}${this.startMonth}`,
                    periodTwo: `${this.endYear}${this.endMonth}`,
                    branch: this.unit,
                    accounting: this.accounting,
                    nature: this.nature
                }

                try {
                    const validRepeated = await axios.post(url, bodyContent)

                    if (!validRepeated || validRepeated.status !== 200) {
                        this.$toast.error('Erro ao consultar dadso repetidos', {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                    }

                    if (validRepeated.data.length > 0) {
                        this.$toast.error('Já existem lançamentos neste período, centro de custo ou natureza para a unidade!!', {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                    } 

                    for (const item of periodContent) {

                        const url = `${process.env.VUE_APP_BASE_API_URL}/saveDataCpo`;

                        const bodyInsert = {
                            period: item.period,
                            branch: this.unit,
                            accounting: this.accounting?.trim() ?? '',
                            nature: this.nature?.trim() ?? '',
                            cost: Number(`${value}.${cents}`)
                        }

                        try {
                            const result =  await axios.post(url, bodyInsert)

                            if (!result || result.status !== 200) {
                                this.$toast.error('Erro ao incluir dados', {
                                    position: "bottom-center",
                                    timeout: 5000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false,
                                });
                                this.dialogAwait = false
                                return
                            }

                        } catch (error) {
                            this.$toast.error(error.response.data, {
                                position: "bottom-center",
                                timeout: 5000,
                                closeOnClick: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false,
                            });
                            this.dialogAwait = false
                            return
                        }
                    }

                    this.$toast.success("Incluído com sucesso!", {
                        position: "bottom-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                } catch (error) {
                    this.$toast.error(error.response.data, {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                }
            } else {

                let url = `${process.env.VUE_APP_BASE_API_URL}/getDataCpo`;

                const bodyContent = {
                    periodOne: `${this.startYear}${this.startMonth}`,
                    periodTwo: `${this.startYear}${this.startMonth}`,
                    branch: this.unit,
                    accounting: this.accounting,
                    nature: this.nature
                }

                try {
                    const validRepeated = await axios.post(url, bodyContent)

                    if (!validRepeated || validRepeated.status !== 200) {
                        this.$toast.error('Erro ao consultar dadso repetidos', {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                    }

                    if (validRepeated.data.length > 0) {
                        this.$toast.error('Já existem lançamentos neste período, centro de custo ou natureza para a unidade!!', {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                    }

                    const bodyInsert = {
                        period: `${this.startYear}${this.startMonth}`,
                        branch: this.unit,
                        accounting: this.accounting?.trim() ?? '',
                        nature: this.nature?.trim() ?? '',
                        cost: Number(`${value}.${cents}`),
                    }

                    url = `${process.env.VUE_APP_BASE_API_URL}/saveDataCpo`;

                    try {
                        const result = await axios.post(url, bodyInsert)

                        if (!result || result.status !== 200) {
                            this.$toast.error('Erro ao incluir dados', {
                                position: "bottom-center",
                                timeout: 5000,
                                closeOnClick: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false,
                            });
                            this.dialogAwait = false
                            return
                        }

                        this.$toast.success("Incluído com sucesso!", {
                            position: "bottom-center",
                            timeout: 5000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                    } catch (error) {
                        this.$toast.error(error.response.data, {
                            position: "bottom-center",
                            timeout: 10000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.dialogAwait = false
                        return
                    }
                } catch (error) {
                    this.$toast.error(error.response.data, {
                        position: "bottom-center",
                        timeout: 10000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                    this.dialogAwait = false
                    return
                }
                
            }

            

            this.clearForm()

            this.dialogAwait = false
        },
        async saveEdit() {
            this.dialogAwait = true
            const value = this.cost.split(',')[0]
            const cents = this.cost.split(',')[1] ?? '00'
            const bodyEdit = {
                cost: Number(`${value.toString().replace('.', '')}.${cents}`),
                recno: this.editedItem.R_E_C_N_O_
            }

            const url = `${process.env.VUE_APP_BASE_API_URL}/saveEditCpo`;

            await axios.post(url, bodyEdit).catch(err => {
                this.$toast.error(err.response.data, {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.dialogAwait = false
                return
            })

            this.$toast.success("Editado com sucesso!", {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
            });

            this.dialogEdit = false

            this.dialogAwait = false

            this.searchData()
        },
        async deleteItem(item) {
            this.dialogAwait = true
            const url = `${process.env.VUE_APP_BASE_API_URL}/deleteCpo`;

            await axios.post(url, { recno: item.R_E_C_N_O_ }).catch(err => {
                this.$toast.error(err.response.data, {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: false,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.dialogAwait = false
                return
            })

            this.$toast.error("Excluido com sucesso!", {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false,
            });

            this.searchData()
        },
        clearForm() {
            this.startYear = null
            this.endYear = null
            this.startMonth = null
            this.endMonth = null
            this.unit = null
            this.accounting = null
            this.nature = null
            this.cost = null
            this.appellant = false
        }
    },
    async mounted() {
        this.dialogAwait = true
        await this.searchYears();
        await this.searchBranches();
        await this.searchNature();
        await this.searchAccounting();
        this.dialogAwait = false
    }
}
</script>
<style></style>